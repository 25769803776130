import {
  createPageUrl,
  SECTION_HOMEPAGE,
  getLastPage,
} from '@wix/communities-blog-client-common';
import {
  getFeedSEOLinks,
  getFeedSEOMetaTags,
  getFeedSEOTitle,
} from '../../../feed-page/selectors/feed-seo-tags-selectors';
import {
  getEntityCount,
  getPaginationPageSize,
} from '../../selectors/pagination-selectors';
import { getTopology } from '../../store/topology/topology-selectors';
import { getMultilingualQueryParam } from '../get-multilingual-query-param';
import { formatTitle } from '../pagination';
import { getPaginationUrls } from './get-pagination-item-data';

export const generateFeedSEOTags = ({
  state,
  page = 1,
  t,
  showPagination,
  multilingual,
}) => {
  const { baseUrl, sectionUrl, isHomePage } = getTopology(state);
  const multilingualQueryParam = getMultilingualQueryParam(multilingual);
  const canonicalUrl = `${
    page === 1
      ? isHomePage
        ? baseUrl
        : sectionUrl
      : createPageUrl(page, sectionUrl)
  }${multilingualQueryParam}`;

  const pageSize = getPaginationPageSize(state, SECTION_HOMEPAGE);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);

  const title = getFeedSEOTitle(state, { showPagination, page, lastPage, t });
  const links = getFeedSEOLinks(state);
  const metaTags = getFeedSEOMetaTags(state, { title });

  !links.find((item) => item.rel === 'canonical') &&
    links.push({ rel: 'canonical', href: canonicalUrl });
  !metaTags.find((item) => item.property === 'og:url') &&
    metaTags.push({ property: 'og:url', content: canonicalUrl });

  const paginationUrls = getPaginationUrls(
    sectionUrl,
    page,
    lastPage,
    state,
    multilingualQueryParam,
  );
  if (paginationUrls.prevUrl) {
    links.push({ rel: 'prev', href: paginationUrls.prevUrl });
  }
  if (paginationUrls.nextUrl) {
    links.push({ rel: 'next', href: paginationUrls.nextUrl });
  }

  return {
    title,
    links,
    metaTags,
  };
};
